import React from "react";
import "./P404.css";
import BgSvg from "../../svg/CircleBgSvg";
import GoMainSvg from "../../svg/GoMainSvg";

const P404 = () => {
  return (
    <section id="p404">
      <div className="inner">
        <BgSvg className="bg" />
        <BgSvg className="bg" />
        <BgSvg className="bg" />
        <BgSvg className="bg" />

        {/* <Blub className="blub" /> */}
        <div className="svg">
          <p>
            Страница, которую вы запрашиваете, не существует. Возможно она
            устарела, была удалена, или был введен неверный адрес в адресной
            строке.
          </p>
          <svg
            viewBox="0 0 527 1060"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M289.148 278.712C289.148 264.916 299.038 253.353 311.825 248.173C359.047 229.042 392.348 182.798 392.348 128.788C392.348 57.6603 334.592 0 263.348 0C192.103 0 134.348 57.6603 134.348 128.788C134.348 182.798 167.649 229.042 214.87 248.173C227.657 253.353 237.548 264.916 237.548 278.712V1059.5H289.148V278.712ZM340.748 128.788C340.748 171.464 306.094 206.061 263.348 206.061C220.601 206.061 185.948 171.464 185.948 128.788C185.948 86.1113 220.601 51.5152 263.348 51.5152C306.094 51.5152 340.748 86.1113 340.748 128.788Z"
              fill="white"
            />
            <path
              d="M148.75 146.25H118.75V36H81.25L0 144V170H93.75V211H118.75V170H148.75V146.25ZM24.25 146.25L93.75 53.75V146.25H24.25Z"
              fill="#38877B"
            />
            <path
              d="M526.416 146.25H496.416V36H458.916L377.666 144V170H471.416V211H496.416V170H526.416V146.25ZM401.916 146.25L471.416 53.75V146.25H401.916Z"
              fill="#38877B"
            />
          </svg>
          <GoMainSvg onClick={() => { window.location.href = '/'}} className="gomain" />
        </div>
      </div>
    </section>
  );
};

export default P404;
