import React, { useState, useContext, useEffect } from "react";
import ArrowSvg from "../../svg/ArrowSvg";
import InputMask from "react-input-mask";
import ReCAPTCHA from "react-google-recaptcha";
import { AppContext } from "../context/context";
import checkPrefix from "../../hooks/usePhoneFormat";

const Request = ({ formId }) => {
    const { setIsSubmitted, setIsOpen } = useContext(AppContext);
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [agreement, setAgreement] = useState(false);
    const [captcha, setCaptcha] = useState(false);
    const [capVal, setCapVal] = useState(null);
    const [sended, setSended] = useState(false);

    const [nameErr, setNameErr] = useState(false);
    const [phoneErr, setPhoneErr] = useState(false);
    const [agrErr, setAgrErr] = useState(false);
    const [captchaErr, setCaptchaErr] = useState(false);

    const urlParams = new URLSearchParams(window.location.search);
    const utmSource = urlParams.get("utm_source") ?? "/";
    const utmMedium = urlParams.get("utm_medium") ?? "/";
    const utmCampaign = urlParams.get("utm_campaign") ?? "/";
    const utmContent = urlParams.get("utm_content") ?? "/";
    const utmTerm = urlParams.get("utm_term") ?? "/";
    const utmPlatform = urlParams.get("utm_platform") ?? "/";

    const utmRoute = "/";
    const utmFlat = "/";
    const typeOfPremises = "apartment";

    const gclid = urlParams.get("gclid") ?? "/";
    const yclid = urlParams.get("yclid") ?? "/";
    const fbclid = urlParams.get("fbclid") ?? "/";

    const handleSubmit = (e) => {
        e.preventDefault();
        var formData = new FormData();

        if (name === "") {
            setNameErr(true);
            return;
        }

        if (phone.trim().length !== "+6 (674) 636-46-34".length) {
            setPhoneErr(true);
            return;
        }

        if (!checkPrefix(phone)) {
            setPhoneErr(true);
            console.log("2");
            return;
        }

        if (agreement === false) {
            setAgrErr(true);
            return;
        }

        if (!capVal && sended !== false) {
            setCaptchaErr(true);
            return;
        }

        formData.append("name", name);
        formData.append("phone", phone);
        formData.append("checkbox", agreement);
        formData.append("gclid", gclid);
        formData.append("fbclid", fbclid);
        formData.append("yclid", yclid);
        formData.append("form_id", formId);
        formData.append("utm_source", utmSource);
        formData.append("utm_medium", utmMedium);
        formData.append("utm_campaign", utmCampaign);
        formData.append("utm_content", utmContent);
        formData.append("utm_term", utmTerm);
        formData.append("utm_flat", utmFlat);
        formData.append("utm_platform", utmPlatform);
        formData.append("utm_route", utmRoute);
        formData.append("fullurl", window.location.href);
        formData.append("type_of_premises", typeOfPremises);

        // var formDataString = Object.fromEntries(formData);
        // console.log(formDataString);
        fetch("send.php", {
            method: "POST",
            body: formData,
        })
            .then((data) => {
                setName("");
                setPhone("");
                setAgreement(false);
                setIsSubmitted(true);
                setIsOpen(false);
                localStorage.setItem("formSended", true);
            })
            .catch((error) => {
                setName("");
                setPhone("");
                setAgreement(false);
                console.error(error);
            });
    };

    useEffect(() => {
        const capthcaState = JSON.parse(localStorage.getItem("formSended"));

        if (capthcaState !== null) {
            setSended(capthcaState);
            console.log(sended);
        }
    }, []);

    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <form onSubmit={handleSubmit}>
            <input type="hidden" id="gclid_field" name="gclid_field" value="" />
            <div className="text">
                <label htmlFor="name">Имя</label>
                <input
                    type="text"
                    name="name"
                    placeholder="Введите имя"
                    value={name}
                    onChange={(e) => {
                        setName(e.target.value);
                        setNameErr(false);
                    }}
                />
                <div className="error">{nameErr ? "Вы должны заполнить данное поле" : ""}</div>
            </div>
            <div className="text">
                <label htmlFor="phone">Телефон</label>
                <InputMask
                    mask="+7 (799) 999-99-99"
                    maskChar=" "
                    type="text"
                    id="phone"
                    placeholder="Введите номер"
                    value={phone}
                    onChange={(e) => {
                        setPhone(e.target.value);
                        setPhoneErr(false);
                    }}
                />
                <div className="error">{phoneErr ? "Неверный номер телефона" : ""}</div>
            </div>
            <div>
                <label>
                    <input
                        type="checkbox"
                        name="key"
                        value="value"
                        checked={agreement}
                        onChange={(e) => {
                            setAgreement(e.target.checked);
                            setAgrErr(false);
                        }}
                    />
                    <div>
                        <span>Я согласен(а) на обработку моих персональных данных.</span>
                    </div>
                </label>
                <div className="error">{agrErr ? "Вы должны быть согласны на обработку данных" : ""}</div>
            </div>
            {sended ? (
                <div className="captcha">
                    <ReCAPTCHA sitekey="6LfpjWspAAAAAPp4W_ZbppAA_9U10CSuEgAlZDHz" onChange={(val) => setCapVal(val)} />
                    <div className="error">{captchaErr ? "Подтвердите что вы не робот" : ""}</div>
                </div>
            ) : null}
            ,
            <button onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} type="submit">
                Отправить <ArrowSvg className="arrow" />
            </button>
        </form>
    );
};

export default Request;
