import "./App.css";
import Header from "./components/header/Header";
import Baner from "./components/baner/Baner";
import Trim from "./components/trim/Trim";
import Video from "./components/video/Video";
import Benefits from "./components/benefits/Benefits";
import Map from "./components/map/Map";
import AllInOne from "./components/allInOne/AllInOne";
import Gallery from "./components/gallery/Gallery";
import Advantages from "./components/advantages/Advantages";
import Form from "./components/form/Form";
import Footer from "./components/footer/Footer";
import Phone from "./components/phone/Phone";
import React, { useRef, useEffect, useContext } from "react";
import ModalForm from "./components/modalForm/ModalForm";
import P404 from "./components/p404/P404";
import { AppContext } from "./components/context/context";
import Submited from "./components/submited/Submited";
import QA from "./components/qa/QA";
import Progress from "./components/progress/Progress";
import Panorama from "./components/panorama/Panorama";

function App() {
    const { isOpen, setIsOpen, is404, setIs404, isSubmitted } = useContext(AppContext);

    const progressBarRef = useRef(null);
    const contentRef = useRef(null);

    useEffect(() => {
        const currentPath = window.location.pathname;
        if (currentPath !== "/") {
            setIs404(true);
        }
    }, [window.location.pathname]);

    // useEffect(() => {
    //   let progressBar = progressBarRef.current;
    //   let content = contentRef.current;
    //   let bounds = content.getBoundingClientRect();

    //   let scroller = {
    //     wheelMultiplier: getLineHeight(),
    //     ease: 0.07,
    //     speed: 0,
    //     minY: 0,
    //     maxY: bounds.height - window.innerHeight,
    //     y: 0
    //   };

    //   window.scrollTo(0, 0);

    //   window.addEventListener("wheel", onWheel);
    //   onFrame();

    //   function onFrame() {
    //     scroller.speed += -scroller.speed * scroller.ease;
    //     scroller.y -= Math.round(scroller.speed * 1000) / 1000;

    //     if (scroller.y < scroller.minY) {
    //       scroller.y = scroller.minY;
    //       scroller.speed = 0;
    //     } else if (scroller.y > scroller.maxY) {
    //       scroller.y = scroller.maxY;
    //       scroller.speed = 0;
    //     }

    //     let progress = scroller.y / scroller.maxY;

    //     content.style.transform = `translate3d(0px, ${-scroller.y}px, 1px)`;
    //     progressBar.style.transform = `translate3d(0px, 0px, 0px) scaleY(${progress})`;

    //     requestAnimationFrame(onFrame);
    //   }

    //   function onWheel(event) {
    //     event.preventDefault();

    //     let normalized;
    //     let delta = event.wheelDelta;

    //     if (delta) {
    //       normalized = (delta % 120) === 0 ? delta / 120 : delta / 12;
    //     } else {
    //       delta = event.deltaY || event.detail || 0;
    //       normalized = -(delta % 3 ? delta * 10 : delta / 3);
    //     }

    //     scroller.speed += normalized * scroller.wheelMultiplier;
    //   }

    //   function getLineHeight() {
    //     let element = document.createElement("div");
    //     element.style["font-size"] = "128ex";
    //     document.body.appendChild(element);
    //     let value = getComputedStyle(element).getPropertyValue("font-size");
    //     let size = parseFloat(value, 10) / 128;
    //     document.body.removeChild(element);
    //     return size;
    //   }
    // }, []);

    if (is404) {
        return <P404 />;
    } else if (isSubmitted) {
        return <Submited />;
    }

    return (
        <React.Fragment>
            <div id="content" ref={contentRef}>
                <Header />
                <Baner />
                <Trim />
                <Video />
                <Benefits />
                <Map />
                <AllInOne />
                <Gallery />
                <Panorama />
                <Progress />
                <Form />
                <Advantages />
                <QA />
                <Footer />
                <Phone />
                <ModalForm isOpen={isOpen} setIsOpen={setIsOpen} formId="pop-up" />
            </div>
            <div className="progress-bar" ref={progressBarRef}></div>
        </React.Fragment>
    );
}

export default App;
