import React, { useState } from "react";
import "./Form.css";
import Request from "../request/Request";

const Form = () => {
    return (
        <section id="form">
            <div className="inner">
                <div className="left">
                    <h2>Успейте приобрести квартиру мечты</h2>
                    <p>
                        Оставьте заявку, чтобы получить индивидуальную
                        консультацию!
                    </p>
                </div>
                <div className="right">
                    <Request formId="body" />
                </div>
            </div>
        </section>
    );
};

export default Form;
