import * as React from "react";
const SVGComponent = (props) => (
  <svg
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={2.5} cy={2.5} r={2.5} fill="white" />
    <circle cx={2.5} cy={12.5} r={2.5} fill="white" />
    <circle cx={12.5} cy={2.5} r={2.5} fill="white" />
    <circle cx={12.5} cy={12.5} r={2.5} fill="white" />
  </svg>
);
export default SVGComponent;