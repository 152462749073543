const SVGComponent = (props) => (
    <svg
      viewBox="0 0 18 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.89354e-07 1.86852C1.02506e-06 1.06982 0.890147 0.59343 1.5547 1.03647L16.7519 11.1679C17.3457 11.5638 17.3457 12.4362 16.7519 12.832L1.5547 22.9635C0.890146 23.4066 4.78247e-08 22.9302 8.35292e-08 22.1315L9.89354e-07 1.86852Z"
        fill="white"
      />
    </svg>
  );
  export default SVGComponent;