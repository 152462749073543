import { Flex } from "antd";
import React from "react";
import { ReactPhotoSphereViewer } from "react-photo-sphere-viewer";
import PanoramaImage from "../../images/panorama.jpg";

const Panorama = () => {
    return (
        <section id="gallery">
            <div className="inner">
                <Flex vertical gap={20}>
                    <h2>Панорама</h2>
                    <ReactPhotoSphereViewer hideNavbarButton src={PanoramaImage} height={500} width={"100%"} />
                </Flex>
            </div>
        </section>
    );
};

export default Panorama;
