import React, {useContext} from 'react';
import "./Phone.css";
import { AppContext } from "../context/context";

const Phone = () => {
    const { setIsOpen } = useContext(AppContext);
    return (
        <div className="container">
            <div className="circle" onClick={() => setIsOpen(true)}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={32}
                    height={32}
                    viewBox="0 0 32 32"
                    fill="none"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0.804134 0.221255C0.130116 0.632155 0.000445669 0.99983 1.21291e-06 2.50032C-0.00199884 8.78048 2.46962 15.5483 6.67407 20.7759C12.2139 27.6638 21.0376 32.0027 29.4997 32C31.0002 31.9996 31.3678 31.8699 31.7787 31.1959C31.988 30.8527 31.9996 30.6293 32 26.9334C32.0004 23.0705 31.998 23.0295 31.7501 22.6615C31.3095 22.0078 30.9659 21.8884 29.2022 21.7754C26.5783 21.6074 24.7564 21.2004 22.6699 20.3158C21.2642 19.7199 20.8975 19.6196 20.4401 19.7059C19.6195 19.8609 19.382 20.1213 18.6021 21.7204C18.1992 22.5465 17.8304 23.2224 17.7826 23.2224C17.6139 23.2224 15.4875 21.7471 14.4893 20.9374C12.6067 19.4103 10.5302 17.0579 9.22047 14.9684C8.96069 14.5539 8.77813 14.1845 8.81502 14.1477C8.85191 14.1109 9.54604 13.7501 10.3576 13.3458C11.7352 12.6598 11.8498 12.5781 12.0832 12.1152C12.2208 11.8426 12.3332 11.4547 12.3332 11.2532C12.3332 11.0518 12.0864 10.2999 11.7849 9.58228C10.7927 7.22189 10.3979 5.50506 10.2246 2.79777C10.1116 1.03405 9.99216 0.690489 9.33847 0.249922C8.97046 0.00202598 8.92946 -0.000418603 5.06658 2.58532e-05C1.37071 0.000359195 1.14725 0.0120267 0.804134 0.221255Z"
                        fill="white"
                    />
                </svg>
                <div className="pulse-ring"></div>
                <div className="pulse-ring2"></div>
            </div>
        </div>
    )
}

export default Phone