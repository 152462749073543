import React, { createContext, useState, useEffect } from 'react';

const AppContext = createContext();

const AppProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [is404, setIs404] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const contextValues = {
    isOpen,
    setIsOpen,
    is404,
    setIs404,
    isSubmitted,
    setIsSubmitted,
  };

  return (
    <AppContext.Provider value={contextValues}>
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
