import "./Map.css";
import React, {useState} from 'react';
import MapSvg from "../../svg/MapSvg";
import AnimationText from "../animation/AnimationText";
import Modal from "../modal/Modal";
import ViewImg from "../../images/view.jpg";

const Map = () => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <section id="map">
            <div className="flex">
                <AnimationText id="map1">
                    <h2>В центре жизни</h2>
                </AnimationText>
                <button onClick={() => setIsOpen(true)}>Вид с птичьего полета</button>
            </div>
            <MapSvg />
            <Modal setIsOpen={setIsOpen} isOpen={isOpen}>
                <img src={ViewImg} alt="" />
            </Modal>
        </section>
    )
}

export default Map
