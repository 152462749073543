const SVGComponent = (props) => (
    <svg
        width={30}
        height={30}
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M14 0H16V30H14V0Z" fill="#38877B" />
        <path d="M0 16V14H30V16H0Z" fill="#38877B" />
    </svg>
);
export default SVGComponent;
