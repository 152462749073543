import React, { useState } from "react";
import VideoImg from "../../images/video.jpg";
import "./Video.css";
import PlaySvg from "../../svg/PlaySvg";
import BgSvg from "../../svg/VideoBgSvg";
import AnimationText from "../animation/AnimationText";
import Modal from "../modal/Modal";
import { Parallax } from "react-scroll-parallax";

const Video = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <section id="video">
      <Parallax translateX={[-10, 0]}>
        <BgSvg className="bg" />
      </Parallax>
      <div className="inner">
        <h2>
          <AnimationText id="video1">Изящные</AnimationText>
          <AnimationText id="video2">линии совершенства</AnimationText>
        </h2>
        <AnimationText id="video3">
          <p>
            RAMS Signature – это жилое пространство класса бизнес, для тех, кто
            готов выходить за рамки и гнуть свою линию. Комплекс вдохновлен
            меняющимися тенденциями, и людьми-новаторами. Это то место, которое
            объединяет в себе все самое желанное.
          </p>
          <p>
            Смелые решения откликаются в архитектурной эстетике, которая вносит
            свободу и независимость движения, намеренно искажая типичную фигуру
            здания. Здесь нет углов и ограничений, формы подстраиваются под ваш
            уникальный стиль.
          </p>
        </AnimationText>

        <div className="video-con">
          <PlaySvg className="play" onClick={() => setIsOpen(true)} />
          <img src={VideoImg} alt="" />
        </div>
      </div>
      <Modal setIsOpen={setIsOpen} isOpen={isOpen}>
        <div className="iframe-con">
          <iframe
            id="youtubeiframe553849927"
            class="t331__iframe"
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/IX7Otd3WYfI?autoplay=1&amp;rel=0"
            frameborder="0"
            allowFullScreen=""
          ></iframe>
        </div>
      </Modal>
    </section>
  );
};

export default Video;
