import React from 'react';
import "./Benefits.css";
import Benefit1Img from "../../images/benefit1.jpg";
import Benefit2Img from "../../images/benefit2.jpg";
import Benefit3Img from "../../images/benefit3.jpg";
import Benefit4Img from "../../images/benefit4.jpg";
import Animation from "../../components/animation/Animation";
import AnimationText from '../animation/AnimationText';


const Benefits = () => {
    return (
        <section id='benefits'>
            <div className="flex">
                <div>
                    <Animation type="left" className="img">
                        <img src={Benefit1Img} alt="" />
                    </Animation>
                    <div>
                        <AnimationText id="ben1">
                            <h2>SHOpPing AVENUE</h2>
                        </AnimationText>
                        <AnimationText id="ben2">
                            <p>
                                Захотелось ли вам побаловать себя новыми покупками, а близких
                                подарками или украсить дом к празднику – во всех случаях поможет
                                SHOPPING AVENUE. Прогуливаясь по бульвару бутиков, выбирайте то, что
                                нравится вам.
                            </p>
                        </AnimationText>
                    </div>
                </div>

                <div>
                    <div>
                        <AnimationText id="ben3">
                            <h2>PUBLIC CENTER</h2>
                        </AnimationText>
                        <AnimationText id="ben4">
                            <p>
                                Эта локация станет центром притяжения для тех, кто использует свое
                                время и отдых качественно. Дизайн ландшафта, декоративные и
                                архитектурные элементы создают особую обстановку. Здесь приятно
                                отдыхать, удобно делать впечатляющие снимки для социальных сетей и
                                проводить нетворкинг с окружающими. А в теплые дни можно собраться с
                                семьей, и устроить пикник на специально отведенном месте.
                            </p>
                        </AnimationText>
                    </div>
                    <Animation type="right" className="img">
                        <img src={Benefit2Img} alt="" />
                    </Animation>
                </div>

                {/* <div>
                    <Animation type="left" className="img">
                        <img src={Benefit3Img} alt="" />
                    </Animation>
                    <div>
                        <AnimationText id="ben5">
                            <h2>GREEN THEATER</h2>
                        </AnimationText>
                        <AnimationText id="ben6">
                            <p>
                                Green Theatre станет одной из любимых локаций для многих. Резиденты
                                могут организовать место встречи с друзьями, заказать бодрящий
                                американо, подзарядить телефон или поработать за ноутбуком, не
                                покидая территорию комплекса. Главное преимущество в том, что
                                крытый павильон функционирует круглый год.
                            </p>
                        </AnimationText>
                    </div>
                </div> */}

                <div>
                    <Animation type="left" className="img">
                        <img src={Benefit4Img} alt="" />
                    </Animation>
                    <div>
                        <AnimationText id="ben7">
                            <h2>Мечты о высоком</h2>
                        </AnimationText>
                        <AnimationText id="ben8">
                            <p>
                                Личные терассы RAMS Signature представляет панораму на Заилийский
                                Алатау и городской пейзаж. Следуйте тому образу жизни, о котором вы
                                мечтаете: отдыхайте и расслабляйтесь с прекрасным видом на любимый
                                Алматы, вдыхая свежий воздух. Организовывайте теплые встречи с
                                семьей или домашние вечеринки с друзьями, и просто позвольте себе
                                полный релакс на природе в любое время суток.
                            </p>
                        </AnimationText>
                    </div>
                </div>



            </div>
        </section>
    )
}

export default Benefits