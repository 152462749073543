const SVGComponent = (props) => (
  <svg
    viewBox="0 0 2133 1622"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1089.04 1C706.426 14.3487 117.889 415.735 159.257 629.415C200.625 843.094 833.963 618.12 967.672 905.63C1101.38 1193.14 193.811 1785.42 21.6153 1577.75C-178.809 1336.04 1123.55 392.948 2132 50.5837"
      stroke="url(#paint0_radial_1320_979)"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <radialGradient
        id="paint0_radial_1320_979"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(180 631.5) rotate(48.1426) scale(1328.53 1748.05)"
      >
        <stop stopColor="#38877B" stopOpacity={0} />
        <stop offset={1} stopColor="#38877B" />
      </radialGradient>
    </defs>
  </svg>
);
export default SVGComponent;
