import React, { useState } from "react";
import "./Progress.scss";

import ProgressVideo from "../../images/gallery2.jpg";
import { Progress, ConfigProvider, Modal } from "antd";
import AnimationText from "../animation/AnimationText";
import Animation from "../../components/animation/Animation";

const ComplexProgress = (props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <section id="progress">
            <div className="inner">
                <div className="progress-grid">
                    <div className="progress-grid-item progress-grid-item-left">
                        <AnimationText>
                            <h3 className="progress-grid-item-title">
                                Ход строительства
                            </h3>
                        </AnimationText>
                        <AnimationText>
                            <p className="progress-grid-item-text">
                                Рады поделиться с вами ходом строительства
                                жилого комплекса RAMS Signature. Подробнее
                                смотрите в нашем видео
                            </p>

                            <button
                                className="progress-grid-item-button"
                                onClick={showModal}
                            >
                                Смотреть
                            </button>
                            {/* <div className="progress-status">
                                <div className="progress-status-top">
                                    <span>
                                        Строительные работы завершены на
                                    </span>
                                    <span>70%</span>
                                </div>
                                <ConfigProvider
                                    theme={{
                                        components: {
                                            Progress: {
                                                defaultColor: "#09183B",
                                            },
                                        },
                                    }}
                                >
                                    <Progress
                                        percent={70}
                                        status="active"
                                        showInfo={false}
                                    />
                                </ConfigProvider>
                            </div> */}
                        </AnimationText>
                    </div>
                    <Animation type="right">
                        <div className="progress-grid-item">
                            <img
                                className="progress-grid-item-img"
                                src={ProgressVideo}
                                onClick={showModal}
                            />
                        </div>
                    </Animation>
                </div>
            </div>
            <Modal
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                centered={true}
                footer={null}
                destroyOnClose={true}
            >
                <iframe src="https://www.youtube.com/embed/Tlq2lShb8Jg?si=P_3VbYz2E5Vf97-G"></iframe>
            </Modal>
        </section>
    );
};

export default ComplexProgress;
