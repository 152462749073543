import "./Footer.css";
import React, { useEffect } from 'react';
import FooterLogoSvg from "../../svg/FooterLogoSvg";

const Footer = () => {
    useEffect(() => {
        const smoothScroll = (event) => {
            event.preventDefault();
            const targetId = event.target.getAttribute('href').slice(1);
            const targetElement = document.getElementById(targetId);

            const headerHeight = 0;

            const scrollPosition = targetElement.getBoundingClientRect().top + window.scrollY - headerHeight;

            window.scrollTo({
                top: scrollPosition,
                behavior: 'smooth',
            });
        };

        const anchorLinks = document.getElementsByClassName('link');
        Array.from(anchorLinks).forEach((link) => {
            link.addEventListener('click', smoothScroll);
        });

        return () => {
            Array.from(anchorLinks).forEach((link) => {
                link.removeEventListener('click', smoothScroll);
            });
        };
    }, []);
    return (
        <footer>
            <div className="inner">
                <div className="left">
                    <FooterLogoSvg className="logo2" />
                    <p className="text">Архитектура проекта, изображения благоустройства, фасадов, интерьера, применяемых материалов, их нумерация, инфраструктура комплекса являются условными и могут быть изменены в ходе проектирования, строительства и эксплуатации в рамках положительного заключения государственной экспертизы. Настоящая реклама, в соответствии со ст. 395 ГК РК не является публичной офертой.</p>
                </div>
                <div className="right">
                    <div className="links">
                        <a href="#trim" className="link">Чистовая отделка</a>
                        <a href="#map" className="link">Расположение</a>
                        <a href="#video" className="link">О проекте</a>
                        <a href="#all-in-one" className="link">All in one</a>
                    </div>
                    <div className="phone">
                        <a href="tel: +77072124444">+7 707 212 44 44</a>
                    </div>
                    <div className="adresses">
                        <h3>Офисы продаж</h3>
                        <div className="adress">
                            Пн-Пт<span> 10:00-20:00</span> <br />
                            Сб-Вс<span> 10:00-19:00</span>
                        </div>
                        <div className="adress">
                            г. Алматы, <br />
                            пр. Суюнбая 15б
                        </div>
                        <div className="adress">
                            г. Алматы, <br />
                            ул. Тимирязева, 37
                        </div>
                        <div className="adress">
                            г. Алматы, <br />
                            ул. Айманова, 120
                        </div>
                        <div className="adress">
                            г. Алматы, <br />
                            ул. Жандосова, 94а
                        </div>
                    </div>
                </div>
                <FooterLogoSvg className="logo1" />
            </div>
        </footer>
    )
}

export default Footer