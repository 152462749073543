import React, { useEffect, useState } from 'react';
import { motion, Variants } from "framer-motion";

const AnimationText = ({ children, id }) => {
    const [opacity, setOpacity] = useState(0);
    const [transform, setTransform] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const element = document.getElementById(id);

            if (element) {
                const windowHeight = window.innerHeight;
                const { top } = element.getBoundingClientRect();
                const distance = windowHeight * 0.2 - Math.max(top, 0); // Calculate the distance from 200px from top

                const minDistance = 0; // Minimum distance for full opacity and no transform
                const maxDistance = 200; // Maximum distance for zero opacity and full transform

                const calculatedOpacity = 1 - distance / maxDistance;
                const calculatedTransform = (distance / maxDistance) * 100;

                setOpacity(calculatedOpacity);
                setTransform(calculatedTransform);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [id]);

    const cardVariants = {
        offscreen: {
            y: 100,
            opacity: 0,
        },
        onscreen: {
            y: 0,
            opacity: 1,
            transition: {
                type: "tween",
                duration: 1
            }
        },
    };


    return (
        // <motion.div

        //     style={{
        //         // width: "100%",
        //         transition: "1s",
        //         // opacity: opacity,
        //         // transform: `translateY(-${transform}px)`
        //     }}
        //     id={id}
        // >
        //     {children}
        // </motion.div>
        <motion.div
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.8 }}
        >
            <div />
            <motion.div variants={cardVariants}>
                {children}
            </motion.div>
        </motion.div>
    );
};

export default AnimationText;
